import { createListenerMiddleware } from '@reduxjs/toolkit';

import {
    expandStepAction,
    headerAndFooterAction,
    saveHeaderAndFooterAction,
    selectionInfoAction,
    tipAction,
} from 'state/actions';
import { RootState } from 'state/store.types';
import { HeaderAndFooterState, StepCommonMethods } from 'state/types';
import { saveTemplateStep } from 'services';
import { stepBase } from './stepBase';

export const headerAndFooterMiddleware = createListenerMiddleware<RootState>();

headerAndFooterMiddleware.startListening({
    actionCreator: saveHeaderAndFooterAction,
    effect: async ({ payload }, { dispatch, getState }) => {
        const { template } = getState();
        const { id, headerAndFooter } = template;

        dispatch(headerAndFooterAction({ loading: true }));

        await saveTemplateStep(id, 'STEP_1', {
            headerHeight: headerAndFooter.items[0].value || 0,
            footerHeight: headerAndFooter.items[1].value || 0,
        });

        dispatch(headerAndFooterAction({ loading: false }));

        if (!payload?.saveAll) {
            dispatch(expandStepAction(1));
        }
    },
});

headerAndFooterMiddleware.startListening({
    actionCreator: headerAndFooterAction,
    effect: ({ payload }, { dispatch }) => {
        if (payload.active === 0) {
            dispatch(selectionInfoAction({ type: 'header', text: 'header' }));
            dispatch(tipAction('Drag to mark **Document Header**'));
        }

        if (payload.active === 1) {
            dispatch(selectionInfoAction({ type: 'footer', text: 'footer' }));
            dispatch(tipAction('Drag to mark **Document Footer**'));
        }

        if (payload.active === null) {
            dispatch(selectionInfoAction(null));
            dispatch(tipAction(null));
        }

        if (payload.items) {
            const active = headerAndFooter.nextItemIndex(payload.items);

            // Header and Footer should not be validated as user can skip
            // this step and on save we will set 0 to those values
            dispatch(headerAndFooter.action({ valid: true, active }));
        }
    },
});

export const headerAndFooter: StepCommonMethods<HeaderAndFooterState> = {
    ...stepBase,

    stepName: 'headerAndFooter',
    action: headerAndFooterAction,

    initState(dispatch, template) {
        const items = [
            {
                title: 'Document header',
                value: template.headerHeight,
                id: 0,
            },
            {
                title: 'Document footer',
                value: template.footerHeight,
                id: 1,
            },
        ];

        dispatch(this.action({ items }));
    },
    stepChange(dispatch, state) {
        this.handleStepChange(dispatch, state.headerAndFooter.items);
    },
    selectionChange(dispatch, state, { size }) {
        if (typeof size !== 'number') {
            return;
        }

        const data = state.headerAndFooter;
        const items = data.items.map(item => ({ ...item }));

        if (typeof data.active === 'number') {
            items[data.active].value = size;
        }

        dispatch(this.action({ items }));
    },
    clearValue(dispatch, state, id) {
        const data = state.headerAndFooter;
        const items = data.items.map(item => ({ ...item }));

        if (typeof data.active === 'number') {
            items[id || data.active].value = null;
        }

        dispatch(this.action({ items }));
    },
    validateItem(item) {
        return typeof item.value === 'number' && item.value !== 0;
    },
};
