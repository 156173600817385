import { Button, Form, Input, Select } from 'antd';

import { useAppDispatch } from 'hooks/redux.hooks';
import { setTemplateSettings } from 'services';
import { useTemplate } from '../../useTemplate.hook';

import { getTemplateDataAction } from 'state/actions';
import { CommonForm, Container, ToggleButton } from '../../../atomic';
import { CustomSettings } from '../../types';
import { useDrawer } from '../../../DrawerContainer';
import { useState } from 'react';

export const SetAdvancedSettingsForm = ({ templateId }: Props) => {
    const { template } = useTemplate();
    const dispatch = useAppDispatch();
    const [form] = Form.useForm<CustomSettings>();
    let customSettings = template?.customSettings;
    const { closeDrawer } = useDrawer();
    const [open, setOpen] = useState(!!template?.customSettings.splitDocByFields.length || false);

    const handleFinish = async (values: CustomSettings) => {
        await setTemplateSettings(templateId, { customSettings: values });

        dispatch(getTemplateDataAction());
        closeDrawer();
    };

    const selectFieldsOptions: selectFieldsOptions[] | undefined = template?.fields
        .filter(field => !field.isTable)
        .map(({ label, fieldId }) => ({
            label,
            value: fieldId.toString(),
        }));

    return (
        <div className="separators-form">
            <CommonForm
                name="setCustomSettings"
                initialValues={customSettings}
                onFinish={handleFinish}
                form={form}>
                <Container>
                    <Form.Item<CustomSettings>
                        name="joinCloseBoxes"
                        required={false}
                        valuePropName="checked">
                        <ToggleButton label="Join close boxes" />
                    </Form.Item>
                    <Form.Item<CustomSettings>
                        label="Space split threshold"
                        name="spaceSplitThreshold"
                        required={false}>
                        <Input />
                    </Form.Item>
                    <Form.Item<CustomSettings>
                        name="useSortByPosition"
                        required={false}
                        valuePropName="checked">
                        <ToggleButton label="Sort by Position" />
                    </Form.Item>
                    <ToggleButton
                        label="Select fields which separate docs"
                        checked={open}
                        onChange={() => setOpen(!open)}
                    />
                    {open && (
                        <Form.Item<CustomSettings> name="splitDocByFields">
                            <Select
                                className="split-docs-by-fields"
                                placeholder="Select fields"
                                mode="multiple"
                                options={selectFieldsOptions}
                                disabled={!selectFieldsOptions}
                            />
                        </Form.Item>
                    )}
                </Container>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
                <Button type="link">Cancel</Button>
            </CommonForm>
        </div>
    );
};

interface Props {
    templateId: number;
}
interface selectFieldsOptions {
    value: string;
    label: string;
}
