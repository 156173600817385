import { IntegrationSystem } from 'components/SystemSettings/types';
import { TemplateData } from 'components/Template';

export enum DocumentActionTypes {
    IMPORT_DOCUMENTS = 'IMPORT_DOCUMENTS',
    IMPORT_RESULT = 'IMPORT_RESULT',
    LOADING = 'LOADING',
    CURRENT_ID = 'CURRENT_ID',
    DOCUMENT_DATA = 'DOCUMENT_DATA',
    HIGHLIGHT = 'HIGHLIGHT',
    CURRENT_PAGE = 'CURRENT_PAGE',
}

export interface DocumentState {
    loading: boolean;
    currentId: number | null;
    document: TemplateData | null;
    highlight: number[] | null;
    currentPage: number;
}

export interface ImportResult {
    id: unknown;
    success: boolean;
    externalId: unknown;
    errorMessage: unknown;
    errorDetails: unknown;
    integrationSystem: IntegrationSystem;
}

export interface ImportResultData {
    id: number;
    templateId: number;
    fileKey: string;
    totalPages: number;
    success: boolean;
    errorMessage: string | null;
    confirmed: string | null;
    createdAt: string;
    confirmedAt: string | null;
    template: string;
    integrationResults: ImportResult[];
    documentId: string;
    amount: string;
    typeLabel: string;
    templateTypeId: number;
}
