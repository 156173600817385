import classNames from 'classnames';

export const SingleListItem = ({ children, index, isActive, onClick }: Props) => {
    const handleClick = () => {
        if (onClick && typeof index === 'number') {
            onClick(index);
        }
    };

    return (
        <div
            onClick={handleClick}
            className={classNames('single-list-item', isActive && 'single-list-item-selected')}>
            {children}
        </div>
    );
};

interface Props extends WithChildren {
    index?: number;
    isActive?: boolean;
    onClick?: (index: number) => void;
}
