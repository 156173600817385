import classNames from 'classnames';

import { DocumentIcon } from 'components/atomic';

export const DocumentTab = ({ id, isActive, onClick }: Props) => {
    const className = classNames('document-tab', isActive && 'active');

    return (
        <div className={className} onClick={onClick}>
            <div className="icon">
                <DocumentIcon />
            </div>
            <div className="text">Import #{id}</div>
        </div>
    );
};

interface Props extends WithId {
    isActive: boolean;
    onClick: () => void;
}
