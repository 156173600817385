import { ImportResult } from 'state/types';

export const getTitle = (result: ImportResult | null) => {
    if (!result || !result.success) {
        return 'Invoice Imported with errors';
    }

    return 'Invoice Imported successfully';
};

export const getDetails = (result: ImportResult | null) => {
    if (!result) {
        return null;
    }

    if (result.errorDetails) {
        return result.errorDetails as string;
    }
    if (result.externalId) {
        return 'External ID: ' + result.externalId;
    }
};
