import { TemplateData } from 'components/Template';
import { Divider } from 'components/atomic';
import { ExtractedField } from './ExtractedField';
import { ExtractedRemoveLine } from './ExtractedRemoveLine';
import classNames from 'classnames';

export const ExtractedPreview = ({ data, orderId }: Props) => {
    const { extractResult, fields, extractedFields } = data;
    const generalFields = fields
        .filter(field => !field.isTable)
        .map(field => ({
            field,
            value: extractResult.order_header[field.fieldId] || '',
        }));

    const tableFields = fields.filter(field => field.isTable);
    const tableHeader = tableFields.map(field => field.label);
    const tableRows = extractResult.order_lines.map(record =>
        tableFields.map(field => ({
            field,
            value: record[field.fieldId] || '',
        }))
    );

    return (
        <div className="extracted-preview">
            <div className="extracted-preview-title">General Fields</div>
            <Divider />
            <table className="extracted-preview-general-fields">
                <tbody>
                    {generalFields.map(({ field, value }) => (
                        <ExtractedField
                            field={field}
                            extractedValue={value}
                            orderId={orderId}
                            key={field.fieldId}
                            extractedFields={extractedFields}
                        />
                    ))}
                </tbody>
            </table>
            <Divider />
            <div className="extracted-preview-title">Table Values</div>
            <table cellPadding={0} cellSpacing={0} className="extracted-preview-table">
                <thead>
                    <tr>
                        {tableHeader.map((item, index) => (
                            <th key={'field' + index}>{item}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableRows.map((row, rowIndex) => (
                        <tr key={'tableRow' + rowIndex.toString()}>
                            {row.map(({ field, value }, colIndex, cols) => {
                                const last = colIndex + 1 === cols.length && orderId;
                                const className = classNames(last && 'extracted-preview-last');

                                return (
                                    <td
                                        key={
                                            'tableCell' + rowIndex.toString() + colIndex.toString()
                                        }>
                                        <div className={className}>
                                            <ExtractedField
                                                field={field}
                                                extractedValue={value}
                                                orderId={orderId}
                                                rowIndex={rowIndex}
                                                extractedFields={extractedFields}
                                            />
                                            {last && (
                                                <ExtractedRemoveLine
                                                    orderId={orderId}
                                                    lineIndex={rowIndex}
                                                />
                                            )}
                                        </div>
                                    </td>
                                );
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

interface Props {
    data: TemplateData;
    orderId?: number;
}
