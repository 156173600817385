import { useEffect, useState } from 'react';

import { TemplateFieldResponseData } from 'components/Templates';
import { apiRoutes, editDocument } from 'services';
import { FieldEditData } from './types';
import { ExtractedEdit } from './ExtractedEdit';
import { TemplateData } from 'components/Template';
import { useDocumentHighlight } from 'components/Document/DocumentPreview/helpers';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { getListenerAction } from 'hooks/data.hooks';

export const ExtractedField = ({
    field,
    extractedValue,
    orderId,
    rowIndex,
    extractedFields,
}: Props) => {
    const dispatch = useAppDispatch();
    const currentId = useAppSelector(state => state.document.currentId);
    const [editMode, setEditMode] = useState(false);
    const [value, setValue] = useState(extractedValue || '');
    const [loading, setLoading] = useState(false);
    const editable = typeof orderId === 'number';
    const tableField = typeof rowIndex === 'number';

    const toggleEditMode = () => {
        setEditMode(prev => !prev);
    };
    const handleCancelPress = () => {
        setValue(extractedValue || '');
        toggleEditMode();
    };

    const handleSavePress = async () => {
        setLoading(true);

        const dataToSave: FieldEditData = {
            id: field.fieldId,
            propertyName: field.propertyName,
            value,
            isTable: false,
        };

        if (tableField) {
            dataToSave.index = rowIndex;
            dataToSave.isTable = true;
        }

        if (editable) {
            await editDocument(orderId, dataToSave);
        }

        setLoading(false);
        toggleEditMode();
        dispatch(getListenerAction(`${apiRoutes.document}/${currentId}`));
    };

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        setValue(event.target.value);
    };

    useEffect(() => {
        setValue(extractedValue || '');
    }, [extractedValue]);

    if (tableField) {
        const extractedField = extractedFields.find(item => item.id === field.fieldId);
        const mapping = extractedField?.extractionMapping;
        const highlightValue = mapping?.content.map(item => item.index) || null;
        const highlightPage = mapping?.content[0]?.pageNum;
        const highlight = useDocumentHighlight(highlightValue, highlightPage);

        return (
            <div className="extracted-preview-edit" {...highlight}>
                <ExtractedEdit
                    error={extractedField?.error}
                    errorReason={extractedField?.errorReason}
                    editMode={editMode}
                    editable={editable}
                    loading={loading}
                    value={value}
                    onChange={handleChange}
                    onSave={handleSavePress}
                    onCancel={handleCancelPress}
                    toggleEditMode={toggleEditMode}
                />
            </div>
        );
    }

    const extractedField = extractedFields.find(item => item.id === field.fieldId);
    const mapping = extractedField?.extractionMapping;
    const highlightValue = mapping?.content.map(item => item.index) || null;
    const highlightPage = mapping?.content[0]?.pageNum;
    const highlight = useDocumentHighlight(highlightValue, highlightPage);

    return (
        <tr className="extracted-preview-row">
            <td className="extracted-preview-gf-label">{field.label}</td>
            <td className="extracted-preview-gf-value">
                <div className="extracted-preview-edit" {...highlight}>
                    <ExtractedEdit
                        editMode={editMode}
                        editable={editable}
                        loading={loading}
                        value={value}
                        error={extractedField?.error}
                        errorReason={extractedField?.errorReason}
                        onChange={handleChange}
                        onSave={handleSavePress}
                        onCancel={handleCancelPress}
                        toggleEditMode={toggleEditMode}
                    />
                </div>
            </td>
        </tr>
    );
};

interface Props {
    field: TemplateFieldResponseData;
    extractedFields: TemplateData['extractedFields'];
    extractedValue?: string;
    rowIndex?: number;
    orderId?: number;
}
