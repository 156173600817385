import { useEffect, useState } from 'react';

import { DocumentTab } from './DocumentTab';
import { useRect } from 'hooks/useRect.hook';
import { Select } from 'antd';

const TAB_WIDTH = 215;
const TAB_MARGIN = 20;
const TAB_SIZE = TAB_WIDTH + TAB_MARGIN;
const MORE_WIDTH = 150;

export const DocumentTabBar = ({ ids, activeId, onChange }: Props) => {
    const [tabIds, setTabIds] = useState(ids);
    const { ref, rect } = useRect();

    useEffect(() => {
        if (!rect) {
            return;
        }

        const tabsWidth = ids.length * TAB_SIZE;

        if (rect.width < tabsWidth) {
            const amount = ((rect.width - MORE_WIDTH) / TAB_SIZE) | 0;

            setTabIds(ids.slice(0, amount));
        }
    }, [rect]);

    const handleChange = (id: number) => {
        onChange(id);
    };

    const selectPlaceholder = `${ids.length - tabIds.length} More`;
    const selectOptions: SelectOption[] = ids
        .slice(tabIds.length, ids.length)
        .map(id => ({ value: id, label: `Import #${id}` }));

    return (
        <div className="document-tab-bar" ref={ref}>
            {!!rect &&
                tabIds.map(id => (
                    <DocumentTab
                        id={id}
                        isActive={id === activeId}
                        onClick={() => handleChange(id)}
                        key={id.toString()}
                    />
                ))}
            {tabIds.length < ids.length && (
                <Select
                    placeholder={selectPlaceholder}
                    options={selectOptions}
                    onChange={handleChange}
                />
            )}
        </div>
    );
};

interface Props {
    ids: number[];
    activeId: number;
    onChange: (id: number) => void;
}
