import { HashRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import {
    OverviewPage,
    LoginPage,
    ErrorPage,
    ErrorQueuePage,
    DocumentsPage,
    TemplatesPage,
    SystemSettingsPage,
    UsersPage,
    TemplatePage,
    AddressTablePage,
} from 'pages';
import { routeParams, routes } from './routes';
import { useAuth, useUserData } from 'hooks/user.hooks';
import { DefaultLayout } from 'components/Layouts';
import { UserRole } from 'components/Users/types';
import { DrawerContainer } from 'components/DrawerContainer';
import { DocumentPage } from 'pages/DocumentsPage/DocumentPage';

export function Router() {
    return (
        <HashRouter>
            <DrawerContainer>
                <Routes>
                    <Route path="/" element={<InitialRoute />} errorElement={<ErrorPage />} />
                    <Route
                        path={routes.login}
                        element={
                            <RouteGuard role="GUEST">
                                <LoginPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={routes.singup}
                        element={
                            <RouteGuard role="GUEST">
                                <ErrorPage />
                            </RouteGuard>
                        }
                    />
                    <Route
                        path={routes.overview}
                        element={
                            <UserRoute>
                                <OverviewPage />
                            </UserRoute>
                        }
                    />
                    <Route
                        path={routes.errorQueue}
                        element={
                            <UserRoute>
                                <ErrorQueuePage />
                            </UserRoute>
                        }
                    />
                    <Route
                        path={routes.documents}
                        element={
                            <UserRoute>
                                <DocumentsPage />
                            </UserRoute>
                        }
                    />
                    <Route
                        path={`${routes.documents}/:${routeParams.documentId}`}
                        element={
                            <UserRoute>
                                <DocumentPage />
                            </UserRoute>
                        }></Route>
                    <Route
                        path={routes.templates}
                        element={
                            <EditorRoute>
                                <TemplatesPage />
                            </EditorRoute>
                        }></Route>
                    <Route
                        path={`${routes.templates}/:${routeParams.templateId}`}
                        element={
                            <EditorRoute>
                                <TemplatePage />
                            </EditorRoute>
                        }></Route>
                    <Route
                        path={routes.users}
                        element={
                            <AdminRoute>
                                <UsersPage />
                            </AdminRoute>
                        }
                    />
                    <Route
                        path={routes.addressTable}
                        element={
                            <UserRoute>
                                <AddressTablePage />
                            </UserRoute>
                        }
                    />
                    <Route
                        path={routes.systemSettings}
                        element={
                            <AdminRoute>
                                <SystemSettingsPage />
                            </AdminRoute>
                        }
                    />
                    <Route path="*" element={<ErrorPage />} />
                    <Route path={routes.error} element={<ErrorPage />} />
                </Routes>
            </DrawerContainer>
        </HashRouter>
    );
}

export function InitialRoute() {
    const isAuth = useAuth();
    const route = isAuth ? routes.overview : routes.login;

    return <Navigate to={route} replace />;
}

export function RouteGuard({ children, role }: RouteGuardProps) {
    const isAuth = useAuth();
    const { role: userRole } = useUserData();
    const location = useLocation();

    // redirect unauthenticated users to login page
    if (!isAuth && role !== 'GUEST') {
        return <Navigate to={routes.login} state={{ from: location }} replace />;
    }

    // redirect authenticated users but without admin role to error page
    if (isAuth && role === 'ROLE_ADMIN' && userRole !== 'ROLE_ADMIN') {
        return <Navigate to={routes.error} replace />;
    }

    // redirect authenticated users but without admin role to error page
    if (isAuth && role === 'ROLE_EDITOR' && !['ROLE_ADMIN', 'ROLE_EDITOR'].includes(userRole)) {
        return <Navigate to={routes.error} replace />;
    }

    // redirect authenticated users to overview if try to browse guest pages (e.g. login)
    if (isAuth && role === 'GUEST') {
        return <Navigate to={routes.overview} replace />;
    }

    return children;
}

export function UserRoute({ children }: WithChildren) {
    return (
        <RouteGuard role="ROLE_VIEWER">
            <DefaultLayout>{children}</DefaultLayout>
        </RouteGuard>
    );
}

export function AdminRoute({ children }: WithChildren) {
    return (
        <RouteGuard role="ROLE_ADMIN">
            <DefaultLayout>{children}</DefaultLayout>
        </RouteGuard>
    );
}
export function EditorRoute({ children }: WithChildren) {
    return (
        <RouteGuard role="ROLE_EDITOR">
            <DefaultLayout>{children}</DefaultLayout>
        </RouteGuard>
    );
}

interface RouteGuardProps {
    children: JSX.Element;
    role: UserRole;
}
